import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';
import validateAccessKey from 'br-validate-dfe-access-key';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { Button, GoogleRecaptcha, Input } from 'components';

const StyledRow = styled(Row)`
  display: flex;
  width: 600px;

  @media screen and (max-width: 768px) {
    width: 450px;
  }
`;

const WrapperInput = styled(Col)`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  input::placeholder {
    font-family: 'Rubik';
    color: ${theme('colors.gray400')};
  }
`;

const WrapperButton = styled(Col)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 24px;

  button {
    height: 48px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding-left: 16px;

    button {
      margin-top: 16px;
    }
  }
`;

export default class extends React.Component {
  componentDidMount() {
    const { resetSingleKeyQuery } = this.props;
    resetSingleKeyQuery();
  }

  isCte = (key) => {
    const keyData = validateAccessKey(key, {
      describe: true,
      ignoreType: true,
    });
    return keyData.model.value === '57'; // 57 === CTe, 55 === NFe
  };

  validateAccessKey = () => {
    const {
      inputValue,
      onSubmit,
      toggleNotificationCard,
      trackSingleConsultError,
      trackSingleConsultSuccess,
    } = this.props;

    const input = inputValue.replace(/\D/g, '');

    if (input.length !== 44) {
      toggleNotificationCard('home-less-than-forty-four');
      trackSingleConsultError('Chave de acesso menor que 44 caracteres');
    } else if (!input.match(/^\d{44}$/)) {
      toggleNotificationCard('home-invalid');
      trackSingleConsultError('Chave de acesso inválida');
    } else if (this.isCte(input)) {
      toggleNotificationCard('home-is-cte');
    } else {
      onSubmit(trackSingleConsultSuccess, trackSingleConsultError);
    }
  };

  trackSingleConsultErrorCallback = (errorMessage) => {
    const { trackSingleConsultError } = this.props;
    trackSingleConsultError(errorMessage);
  };

  render() {
    const {
      inputValue,
      isLoading,
      googleRecaptchaToken,
      onChange,
      trackSingleConsult,
    } = this.props;

    return (
      <StyledRow>
        <WrapperInput>
          <Input
            placeholder="INSIRA A CHAVE DE ACESSO DA NOTA"
            onChange={onChange}
            value={inputValue}
          />
        </WrapperInput>
        <WrapperButton id="consult-button-container">
          <GoogleRecaptcha />
          <Button
            disabled={
              inputValue === '' ||
              (process.env.NODE_ENV === 'production' &&
                googleRecaptchaToken === '')
            }
            isLoading={isLoading}
            onClick={this.validateAccessKey}
            tracking={trackSingleConsult}
          >
            CONSULTAR
          </Button>
        </WrapperButton>
      </StyledRow>
    );
  }
}
