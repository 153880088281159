import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container as BootstrapContainer } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import {
  effects as userSessionEffects,
  selectors as userSessionSelectors,
} from 'store/user-session';

import { DefaultScreen } from 'layouts';
import { HomeScreen } from 'features/home/Connects';

const Container = styled(BootstrapContainer)`
  padding: 0;

  > div {
    > div {
      display: flex;
      justify-content: center;
    }

    > #home-greeting {
      padding: 56px 0;
      line-height: 1.14;
      font-size: 42px;
      font-weight: 900;
      text-align: center;

      > h1 {
        max-width: 830px;
      }

      @media (max-width: 768px) {
        padding: 24px 0;
        > h1 {
          padding: 0 12px;
          font-size: 22px;
        }
      }

      @media (max-height: 766px) {
        padding: 24px 0;
      }

      @media (max-height: 600px) {
        padding-bottom: 0;
      }
    }
  }
`;

class Home extends Component {
  static getInitialProps({ store }) {
    if (userSessionSelectors.getSignedIn(store.getState())) {
      return { redirect: true, path: '/batch' };
    }

    return { redirect: false };
  }

  componentDidMount() {
    const { trackUserInfo } = this.props;

    trackUserInfo();
  }

  render() {
    return (
      <DefaultScreen>
        <Container fluid>
          <HomeScreen />
        </Container>
      </DefaultScreen>
    );
  }
}

Home.propTypes = {
  trackUserInfo: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  trackUserInfo: () => dispatch(userSessionEffects.trackUserInfo()),
});

export default connect(null, mapDispatchToProps)(Home);
