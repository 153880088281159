import events from '..';

export default function (pagePath, pageTitle) {
  return {
    type: events.singleConsult,
    data: {
      event: events.singleConsult,
      pagePath,
      pageTitle,
    },
  };
}
