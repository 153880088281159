import { connect } from 'react-redux';
import { withTracking } from 'react-tracker';
import SingleConsult from '../Partials/SingleConsult';
import { actions, effects, selectors } from 'store/single-key-query';
import { actions as notificationCardActions } from 'store/notification-card-deprecated';

import singleConsultEvent from 'utils/tracking/events/singleConsult';
import singleConsultSuccessEvent from 'utils/tracking/events/singleConsultSuccess';
import singleConsultErrorEvent from 'utils/tracking/events/singleConsultError';

const mapStateToProps = (state) => ({
  inputValue: selectors.getNfeAccessKey(state),
  isLoading: selectors.getIsFetchingNfe(state),
  googleRecaptchaToken: selectors.getGoogleRecaptchaToken(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (e) => {
    dispatch(actions.setNfeAccessKey(e.target.value));
  },
  onSubmit: (trackSuccess, trackFailure) => {
    dispatch(effects.fetchNfe(trackSuccess, trackFailure));
  },
  resetSingleKeyQuery: () => {
    dispatch(actions.resetSingleKeyQuery());
  },
  toggleNotificationCard: (context) => {
    dispatch(notificationCardActions.setNotificationCardContext(context));
    dispatch(notificationCardActions.toggleNotificationCard());
  },
});

const mapTrackingToProps = (trackEvent) => ({
  trackSingleConsult: () => {
    trackEvent(singleConsultEvent('/', 'Home'));
  },
  trackSingleConsultError: (errorMessage) => {
    trackEvent(singleConsultErrorEvent('/', 'Home', errorMessage));
  },
  trackSingleConsultSuccess: () => {
    trackEvent(singleConsultSuccessEvent('/', 'Home'));
  },
});

export default withTracking(mapTrackingToProps)(
  connect(mapStateToProps, mapDispatchToProps)(SingleConsult),
);
