import { withTracking } from 'react-tracker';
import HomeScreen from '../Partials/HomeScreen';

import pageViewEvent from 'utils/tracking/events/pageView';

const mapTrackingToProps = (trackEvent) => ({
  trackPageView: (pagePath, pageTitle) =>
    trackEvent(pageViewEvent(pagePath, pageTitle)),
});

export default withTracking(mapTrackingToProps)(HomeScreen);
