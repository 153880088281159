import React from 'react';
import { Row, Col } from 'styled-bootstrap-grid';

import styled from 'styled-components';
import { theme } from 'styled-tools';
import Link from 'next/link';

import { Heading } from 'components/_styled';
import { Button, PlainButton } from 'components';

const ButtonsContainer = styled(Col)`
  display: flex;
  justify-content: center;
`;

const SignUpButton = styled(Button)`
  width: 148px;
  height: 48px;
  padding: 0;
  margin-right: 24px;
`;

const LoginButton = styled(PlainButton)`
  width: 148px;
  height: 48px;
  color: ${theme('colors.primary')};
  border: 2px solid ${theme('colors.primary')};
  padding: 16px;
  border-radius: 24px;

  &:hover {
    color: ${theme('colors.primaryHover')};
    border-color: ${theme('colors.primaryHover')};
  }
`;

export default class extends React.Component {
  componentDidMount() {
    const { trackPageView } = this.props;
    trackPageView('/', 'Consulta Simples');
  }

  render() {
    return (
      <Row>
        <Col sm={12} md={8} mdOffset={2} id="home-greeting">
          <Heading.Hero>
            Uma ferramenta para baixar os XMLs e DANFEs das NFes pela Chave de
            Acesso
          </Heading.Hero>
        </Col>
        <Col sm={12} md={8} mdOffset={2}>
          <ButtonsContainer>
            <Link href="/sign/up">
              <SignUpButton>CRIAR CONTA</SignUpButton>
            </Link>
            <Link href="/sign/in">
              <LoginButton>ENTRAR</LoginButton>
            </Link>
          </ButtonsContainer>
        </Col>
      </Row>
    );
  }
}
