import React from 'react';
import {
  Container as BootstrapContainer,
  Row,
  Col,
} from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { theme } from 'styled-tools';

import { DefaultScreen } from 'layouts';
import { PlainButton } from 'components';
import { Heading } from 'components/_styled';

const Container = styled(BootstrapContainer)`
  padding: 0;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    margin-top: 64px;

    > div > h1 {
      margin: 8px 0;
      color: ${theme('colors.gray400')};
    }
  }
`;

export default () => (
  <DefaultScreen>
    <Container>
      <Row>
        <Col sm={12}>
          <Heading.Hero style={{ fontWeight: 'normal' }}>
            Aah não :(
          </Heading.Hero>
        </Col>
        <Col sm={12}>
          <Heading.Hero>
            Infelizmente não damos suporte para o Internet Explorer... Mas você
            pode baixar o navegador Google Chrome{' '}
            <PlainButton
              variant="dark"
              href="https://www.google.com/intl/pt-BR_ALL/chrome/"
              style={{ fontSize: 'inherit' }}
            >
              nesse link
            </PlainButton>{' '}
            ;)
          </Heading.Hero>
        </Col>
      </Row>
    </Container>
  </DefaultScreen>
);
