import events from '..';

export default function (pagePath, pageTitle, errorMessage) {
  return {
    type: events.singleConsultError,
    data: {
      errorMessage,
      event: events.singleConsultError,
      pagePath,
      pageTitle,
    },
  };
}
