import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';

import Home from './home';
import IE from './ie';

export default class extends React.Component {
  static getInitialProps(props) {
    return Home.getInitialProps(props);
  }

  static propTypes = {
    redirect: PropTypes.bool,
    path: PropTypes.string,
  };

  static defaultProps = {
    redirect: false,
    path: null,
  };

  state = {
    isIE: false,
  };

  componentDidMount() {
    const ua = window.navigator.userAgent || '';

    if (ua.indexOf('MSIE ') > 0 || ua.indexOf('Trident/') > 0) {
      this.setState({ isIE: true });
    }

    const { isIE } = this.state;

    const { redirect, path } = this.props;

    if (redirect && !isIE) {
      Router.push(path);
    }
  }

  render() {
    const { redirect } = this.props;
    const { isIE } = this.state;

    if (isIE) {
      return <IE />;
    }

    return !redirect && <Home />;
  }
}
